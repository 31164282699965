import { Entity } from '../entities.model';
import { BudgetCache, Retailer, TabSection } from '../../global/global.model';
import { Plan } from '../plan/plan.model';
import { BudgetAllocation } from '../budget-allocation/budget-allocation.model';
import { Tactic } from '../tactic/tactic.model';
import { Note } from '../note/note.model';
import { v4 as uuidv4 } from 'uuid';
import { Product } from '../product/product.model';
import { Tag } from '../tag/tag.model';
import { User } from '../user/user.model';
import { ColumnCollection } from '../../table/table.model';
import { SOPScenario } from '@vmlyr/size-of-prize-calc';
import { Inputs } from '@vmlyr/size-of-prize-calc/lib/state/scenario/scenario.model';
import { Investment } from '../investment/investment.model';
import { Filter, FilterGroupType } from '../filter/filter.model';
import { Brand, BrandStrategy } from '../brand/brand.model';
import { ExternalId } from '../external-id/external-id.model';
import { ProgramSelect } from '../../../../../../api/src/program/utils/query.utils';
import { BrandInitiative } from '../../../../../../api/src/brand-initiative/brand-initiative.entity';
import { AggregateFunction, Condition, LogicalConjunction } from '../../../../../../api/src/_core/models/math-operations';
import { PublicBudgetPeriod } from '../../../../../../api/src/budget-period/budget-period.entity';
import { TacticsGroup } from '../tactics-groups/tactics-group.model';
import { ProgramClassification, ProgramClassificationStatus } from '../../../../../../api/src/program/program.entity';
import { FileCategories } from '../file/file.model';
import { PercentageOfSpendCompleteColumn } from '../../../../../../api/src/organization/organization.settings';

/**
 * Program model
 * A program is the main entity of the application.  It is a collection of data about a program or campaign
 * that the organization is running.
 *
 * Programs contain budget allocations to represent what money has been allocated to be spent.
 * Programs have objectives and other strategy entities
 * Programs have lots of metadata to group and visualize in summary reports
 * Programs have multiple tactics which hold more cost and performance data
 * Programs have files that can be uploaded to them to represent artifacts of the program.
 */
export interface Program extends Entity {
	id: string;
	name: string;
	atRisk?: boolean;
	budgetPeriod: PublicBudgetPeriod;
	budgetCache?: BudgetCache;
	budgetRecommendation?: BudgetRecommendation;
	plan?: Partial<Plan>;
	planId?: string;
	brandInitiative?: BrandInitiative;
	retailer: Retailer;
	brandStrategy?: string | BrandStrategy[];
	customerStrategy?: string;
	description: string;
	keyLearnings?: string;
	recommendations?: string;
	goal: string;
	programType: ProgramType;
	programUtilization: ProgramUtilization;
	programSector: Sector;
	programPhase: ProgramPhase;
	status: ProgramStatus;
	start: string;
	end: string;
	investments: Investment[];
	budgetAllocations?: BudgetAllocation[];
	tactics?: Tactic[];
	tacticsGroups?: TacticsGroup[];
	brands: Brand[];
	products: Product[];
	owners?: Partial<User>[];
	tags: Tag[];
	notes: Note[];
	externalIds: ExternalId[];
	investmentRecap: boolean;
	objectives: Objectives;
	previousProgram?: Partial<Program>;
	classification?: ProgramClassification;
	classificationStatus?: ProgramClassificationStatus;
}

/**
 * Get the default color of a status
 */
// TODO: We need to move these colors into a similar setup as other settings endpoint entities.
export function getProgramClassificationStatusColor(status: ProgramClassificationStatus): string {
	switch (status) {
		case ProgramClassificationStatus.Draft:
			return '#0075A1';

		case ProgramClassificationStatus.Approved:
			return '#00D185';
	}
}

/**
 * Sectors are larger business groupings that the program existis within.
 * Sectors are configured in the CMS.
 */
export interface Sector extends Entity {}

/**
 * Program types are groups of programs that have a common purpose.
 * Examples: New Product Launch, Existing brand Opportunity, etc.
 * Program Types are configured in the CMS.
 */
export interface ProgramType extends Entity {}

/**
 * Program Utilization are configured in the CMS.
 */
export interface ProgramUtilization extends Entity {}

/**
 * Program phases are the current workflow phases of the program.
 * Program Phases are configured in the CMS.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProgramPhase extends Entity {
	order?: number;
}

/**
 * Program statuses have to do with writing permissions, but aren't currently used in the system.
 */
export type ProgramStatus = 'draft' | 'published' | 'approved';

/**
 * Program Objectives are goals that the program is trying to achieve.
 * Objectives are configured through Geometrics currently but may be used in other ways
 * in the future.
 */
export interface Objectives {
	macroObjectives: Objective[];
	shopperTarget: Objective[];
	subObjectives: Objective[];
	performanceObjectives: Objective[];
	kpis: Objective[];
	program: Objective[];
}

/**
 * A single objective entity contains descriptive metadata to explain the option to users.
 */
export interface Objective {
	id: string;
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
	caption: string;
	category?: string;
	description?: string;
	option?: ObjectiveOption[];
	selected?: boolean;
}

export interface ObjectiveOption {
	title: string;
	optionValues: {
		title: string;
	}[];
	value?: string;
}

/**
 * Budget recommendations are the result of the Size of Prize modules output.
 * They contain scenarios that were worked on and the selected scenarios budget recommendations.
 */
export interface BudgetRecommendation {
	active: SOPScenario['id'];
	benchmarks: Inputs;
	entities: SOPScenario[];
}

export enum ToggleChip {
	Active = 1,
	Inactive = 2,
}

export enum Category {
	FileCategory = 'fileCategory',
	PanelLocationLayoutCodes = 'panelLocationLayoutCodes',
}

/**
 * Create a blank program. Default values go here.
 */
export function createProgram(params: Partial<Program>): Program {
	return {
		id: uuidv4(),
		...params,
	} as Program;
}

/**
 * The current program tab sections.
 */
export const ProgramSections: TabSection[] = [
	{
		label: 'Snapshot',
		slug: 'snapshot',
	},
	{
		label: 'Program Details',
		slug: 'details',
	},
	{
		label: 'Objectives',
		slug: 'objectives',
	},
	{
		label: 'Investments',
		slug: 'investments',
	},
	{
		label: 'Budget',
		slug: 'budget',
	},
	{
		label: 'Tactics',
		slug: 'tactics',
	},
	{
		label: 'Scenarios',
		slug: 'scenarios',
	},
	{
		label: 'Files',
		slug: 'files',
	},
	{
		label: 'Finance',
		slug: 'finance',
	},
	{
		label: 'Reporting',
		slug: 'reporting',
	},
	{
		label: 'Activity',
		slug: 'activity',
	},
];

/**
 * Columns that are unique to programs
 */
export const ProgramColumnCollection: ColumnCollection = {
	id: 'programs',
	name: 'Programs',
	items: [
		{
			id: 'counts',
			name: 'Counts',
			path: 'count',
			type: 'childrenCount',
			category: 'Program',
			entityTypes: ['program'],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			sortable: false,
			hideFromMenu: true,
		},
		{
			id: 'create-child',
			name: 'Create Child Entity',
			path: 'createChild',
			type: 'button',
			category: 'Program',
			entityTypes: ['program'],
			editable: {
				enabled: false,
				hideSnackBar: true,
			},
			button: {
				action: 'createChild',
				type: 'mat-mini-fab',
				icon: 'uil-plus',
				size: 'extra-small',
				color: 'tertiary',
				tooltip: 'Create ##CHILD_ENTITY##',
			},
			extra: {
				width: 180,
				hideHeaderLabel: false,
				useChildEntity: true,
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			sortable: false,
			hideFromMenu: true,
		},
		{
			id: 'brands',
			name: 'Brand(s)',
			path: 'brands',
			exportPath: 'brands.name',
			dependencies: [ProgramSelect.Brands],
			type: 'badges',
			category: 'Program',
			entityTypes: ['program'],
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
				],
			},
			extra: {
				maskPath: 'program.brand.mask',
			},
		},
		{
			id: 'brands',
			name: 'Brand(s)',
			path: 'brands',
			exportPath: 'brands.name',
			dependencies: [ProgramSelect.Brands],
			type: 'badges',
			category: 'Program',
			entityTypes: ['program'],
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
			extra: {
				maskPath: 'mediaPlan.brand.mask',
			},
		},
		{
			id: 'program-classification-status',
			name: 'Plan Status',
			path: 'classificationStatus',
			type: 'badge',
			category: 'Program',
			entityTypes: ['program'],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			hideFromMenu: true,
		},
		{
			id: 'planned-allocated-budget',
			name: 'Plan Allocated Budget',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program', 'tactic'],
			dependencies: [ProgramSelect.BudgetCache],
			sortable: false,
			editable: {
				enabled: false,
				uneditableReason: 'Open Program to edit Brands',
			},
			extra: {
				width: 300,
			},
			hideFromMenu: true,
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
		},
		{
			id: 'retailer',
			name: 'Retailer',
			path: 'retailer',
			exportPath: 'retailer.name',
			type: 'badges',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Retailer],
			editable: {
				type: 'uneditable',
				uneditableReason: 'Retailers are not editable once set.',
			},
			extra: {
				settingsEntity: 'retailers',
				maskPath: 'program.retailer.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'settings.entities.program.retailer.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
		},
		{
			id: 'retailer',
			name: 'Retailer',
			path: 'retailer',
			exportPath: 'retailer.name',
			type: 'badges',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Retailer],
			editable: {
				type: 'uneditable',
				uneditableReason: 'Retailers are not editable once set.',
			},
			extra: {
				settingsEntity: 'retailers',
				maskPath: 'mediaPlan.retailer.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
					{
						path: 'settings.entities.mediaPlan.retailer.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
		},
		{
			id: 'funding-source',
			name: 'Funding Source',
			path: 'fundingSource',
			type: 'custom',
			extra: {
				customMapping: 'fundingSources',
				arrayPath: 'budgetAllocations',
				mapPath: 'fundingSource.name',
			},
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning',
			},
		},
		{
			id: 'funding-type',
			name: 'Funding Type',
			path: 'fundingType',
			type: 'custom',
			extra: {
				customMapping: 'fundingTypes',
				arrayPath: 'budgetAllocations',
				mapPath: 'fundingType.name',
			},
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning',
			},
		},
		{
			id: 'plan-planned-program-budget',
			name: 'Planned Program Budget',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'planning',
					},
					/*{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},*/
				],
			},
		},
		{
			id: 'plan-planned-program-investment',
			name: 'Program Investment',
			path: 'investments',
			exportPath: 'investments',
			type: 'pluckFromArray',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [],
			extra: {
				mask: 'currency',
				aggregateElementsByParentKey: 'Plan',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning',
			},
			aggregate: {
				path: 'amount',
				function: AggregateFunction.Sum,
			},
		},
		{
			id: 'start',
			name: 'Start Date',
			path: 'start',
			type: 'date',
			category: 'Program',
			entityTypes: ['program'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'Start Date is not editable.',
			},
			extra: {
				width: 120,
				maskPath: 'program.start.mask',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning',
			},
		},
		{
			id: 'end',
			name: 'End Date',
			path: 'end',
			type: 'date',
			category: 'Program',
			entityTypes: ['program'],
			editable: {
				type: 'date',
				enabled: true,
				uneditableReason: 'End Date is not editable.',
			},
			extra: {
				width: 120,
				maskPath: 'program.end.mask',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning',
			},
		},
		{
			id: 'date-range',
			name: 'Date Range',
			path: 'date-range', // Doesn't matter for this one
			type: 'date-range',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [],
			sortable: false,
			visibilityCondition: {
				path: 'section',
				condition: Condition.NEQ,
				value: 'media-plan',
			},
		},
		{
			id: 'program-type',
			name: 'Program Type',
			path: 'programType',
			exportPath: 'programType.name',
			type: 'entityName',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.ProgramType],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'programTypes',
				enabled: true,
				uneditableReason: 'Program Type is not editable.',
			},
			extra: {
				maskPath: 'program.programType.mask',
			},
			sortable: false,
		},
		{
			id: 'program-utilization',
			name: 'Program Utilization',
			path: 'programUtilization',
			exportPath: 'programUtilization.name',
			type: 'entityName',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.ProgramUtilization],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'programUtilizations',
				enabled: true,
				uneditableReason: 'Program Utilization is not editable.',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			extra: {
				maskPath: 'program.programUtilization.mask',
			},
			sortable: false,
		},
		{
			id: 'program-sector',
			name: 'Program Sector',
			path: 'programSector',
			exportPath: 'programSector.name',
			type: 'entityName',
			category: 'Program',
			entityTypes: ['program'],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'programSectors',
				enabled: true,
				uneditableReason: 'Program Sector is not editable.',
			},
			dependencies: [ProgramSelect.ProgramSector],
			extra: {
				maskPath: 'program.programSector.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
			sortable: false,
		},
		{
			id: 'program-phase',
			name: 'Program Phase',
			path: 'programPhase',
			exportPath: 'programPhase.name',
			type: 'badge',
			category: 'Program',
			entityTypes: ['program'],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'programPhases',
				enabled: true,
				uneditableReason: 'Program Phase is not editable.',
			},
			extra: {
				settingsEntity: 'programPhases',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			dependencies: [ProgramSelect.ProgramPhase],
			sortable: false,
		},
		{
			id: 'owners',
			name: 'Owners',
			path: 'owners',
			exportPath: 'owners.name',
			type: 'users',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Owners],
			editable: {
				type: 'multi-select',
				filterId: '${type}-owners',
				enabled: true,
				uneditableReason: 'Owners are not editable.',
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
			sortable: false,
		},
		{
			id: 'agency',
			name: 'Agency',
			path: 'agency',
			exportPath: 'agency.name',
			type: 'entityName',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Agency],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'agencies',
				enabled: true,
			},
			extra: {
				maskPath: 'program.agency.mask',
			},
			hideFromMenu: false,
			sortable: false,
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'settings.entities.program.agency.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
		},
		{
			id: 'brand-initiative',
			name: 'Brand Initiative',
			path: 'brandInitiative',
			exportPath: 'brandInitiative.name',
			type: 'entityName',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BrandInitiative],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'brandInitiatives',
				enabled: true,
				uneditableReason: 'Brand Initiative is not editable.',
			},
			extra: {
				maskPath: 'program.brandInitiative.mask',
			},
		},
		{
			id: 'products',
			name: 'Product(s)',
			path: 'products',
			exportPath: 'products.name',
			type: 'entityNames',
			category: 'Program',
			entityTypes: ['program'],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			dependencies: [ProgramSelect.Products],
			sortable: false,
			extra: {
				maskPath: 'program.products.mask',
			},
		},
		{
			id: 'tags',
			name: 'Tags',
			path: 'tags',
			exportPath: 'tags.name',
			type: 'badges',
			category: 'Program',
			dependencies: [ProgramSelect.Tags],
			entityTypes: ['program'],
			editable: {
				type: 'multi-select',
				filterId: '${type}-tags',
				enabled: true,
				uneditableReason: 'Tags are not editable.',
			},
			sortable: false,
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
		},
		{
			id: 'program-country',
			name: 'Location',
			path: 'location.name',
			type: 'field',
			category: 'Program',
			dependencies: [ProgramSelect.Location],
			entityTypes: ['program'],
			extra: {
				maskPath: 'program.location.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'settings.entities.program.location.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
			sortable: false,
		},
		{
			id: 'products',
			name: 'Product(s)',
			path: 'products',
			exportPath: 'products.name',
			type: 'entityNames',
			category: 'Program',
			entityTypes: ['program'],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'planning',
			},
			dependencies: [ProgramSelect.Products],
			sortable: false,
			extra: {
				maskPath: 'program.products.mask',
			},
		},
		{
			id: 'latest-note',
			name: 'Latest Note', // Can use merge tags
			path: 'notes', // Should be unique per type, use merge tags.
			type: 'pluckFromArray',
			category: 'Program',
			exportPath: 'notes', // Has to be the path to the items.
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Notes],
			editable: {
				type: 'notes',
				enabled: true,
				uneditableReason: 'Notes are not editable.',
			},
			extra: {
				tooltip: true,
				stripHtml: true,
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			aggregate: {
				path: 'body', // Property to pluck from
				function: AggregateFunction.First, // 'join', 'count', 'sum', 'average', 'first'
			},
			sortable: false,
		},
		{
			id: 'goal',
			name: 'Goal',
			path: 'goal',
			type: 'field',
			category: 'Program',
			entityTypes: ['program'],
			extra: {
				tooltip: true,
				stripHtml: true,
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
		},
		{
			id: 'description',
			name: 'Purpose',
			path: 'description',
			type: 'field',
			category: 'Program',
			entityTypes: ['program'],
			extra: {
				tooltip: true,
				stripHtml: true,
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
		},
		{
			id: 'keyLearnings',
			name: 'Key Learnings',
			path: 'keyLearnings',
			type: 'field',
			category: 'Program',
			entityTypes: ['program'],
			extra: {
				tooltip: true,
				stripHtml: true,
				maskPath: 'program.keyLearnings.mask',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
		},
		{
			id: 'recommendations',
			name: 'Recommendations',
			path: 'recommendations',
			type: 'field',
			category: 'Program',
			entityTypes: ['program'],
			extra: {
				tooltip: true,
				stripHtml: true,
				maskPath: 'program.recommendations.mask',
			},
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation',
			},
			editable: {
				type: 'textarea',
				enabled: true,
			},
		},
		{
			id: 'funding-source',
			name: 'Funding Source',
			path: 'fundingSource',
			type: 'custom',
			extra: {
				customMapping: 'fundingSources',
				arrayPath: 'budgetAllocations',
				mapPath: 'fundingSource.name',
			},
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
			sortable: false,
		},
		{
			id: 'funding-type',
			name: 'Funding Type',
			path: 'fundingType',
			type: 'custom',
			extra: {
				customMapping: 'fundingTypes',
				arrayPath: 'budgetAllocations',
				mapPath: 'fundingType.name',
			},
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
			sortable: false,
		},
		{
			id: 'budget-event-id',
			name: 'Budget Event ID',
			path: 'budgetEventId',
			type: 'field',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetAllocations],
			category: 'Budgets',
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
			extra: {
				maskPath: 'program.budgetAllocationEventId.mask',
			},
			sortable: false,
		},
		{
			id: 'allocated-budget',
			name: 'Allocated Budget',
			path: 'budgetCache.amountActual',
			exportPath: 'budgetCache.amountActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
				],
			},
		},
		{
			id: 'planned-budget',
			name: 'Planned Budget',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
		},
		{
			id: 'estimated-spend',
			name: 'Estimated Tactic Spend',
			path: 'budgetCache.spendEstimated',
			exportPath: 'budgetCache.spendEstimated',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['tactic'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {
				showDifferenceWith: 'amountActual',
				invertDifference: true,
				width: 230,
			},
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					/*{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},*/
				],
			},
		},
		{
			id: 'actual-spend',
			name: 'Actual Spend (Invoiced)',
			path: 'budgetCache.spendActual',
			exportPath: 'budgetCache.spendActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['tactic'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {
				showDifferenceWith: 'spendEstimated',
				invertDifference: true,
				width: 230,
			},
			editable: {
				type: 'quick-edit-dialog',
				enabled: true,
				dismissWithUpdatedEntity: true,
				modalSize: 'lg',
				rowTypeOverrides: [
					{
						rowType: 'invoice',
						type: 'text',
					},
				],
			},
			visibilityConditions: {
				operator: LogicalConjunction.OR,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
		},
		{
			id: 'percentage-of-budget-spend-complete',
			name: '% of Budget Spend Complete',
			path: 'percentBudgetSpendComplete',
			type: 'percentSpentComplete',
			entityTypes: ['program'],
			category: 'Budgets',
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'settings.percentageOfSpendCompleteColumn',
						condition: Condition.NEQ,
						value: 'off' as PercentageOfSpendCompleteColumn,
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
				],
			},
			sortable: false,
		},
		{
			id: 'percentage-of-tactic-spend-complete',
			name: '% of Tactic Spend Complete',
			path: 'percentTacticSpendComplete',
			type: 'percentSpentComplete',
			entityTypes: ['tactic'],
			category: 'Budgets',
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'settings.percentageOfSpendCompleteColumn',
						condition: Condition.NEQ,
						value: 'off' as PercentageOfSpendCompleteColumn,
					},
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'activation',
					},
				],
			},
			sortable: false,
		},
		{
			id: 'agency',
			name: 'Agency',
			path: 'agency',
			exportPath: 'agency.name',
			type: 'entityName',
			category: 'Program',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.Agency],
			editable: {
				type: 'single-select',
				optionsFromSettings: 'agencies',
				enabled: true,
			},
			extra: {
				maskPath: 'mediaPlan.agency.mask',
			},
			hideFromMenu: false,
			sortable: false,
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
					{
						path: 'settings.entities.mediaPlan.agency.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
		},
		{
			id: 'budget-allocations',
			name: 'Budget Allocations',
			path: 'budgetAllocations',
			type: 'pluckFromArray',
			category: 'Program',
			entityTypes: ['program'],
			hideFromMenu: true,
			dependencies: [ProgramSelect.BudgetAllocations],
			aggregate: {
				path: 'amountActual',
				function: AggregateFunction.Sum,
			},
		},
		{
			id: 'all-external-ids',
			name: 'All External Ids',
			path: 'externalIds',
			exportPath: 'externalIds',
			type: 'field',
			category: 'External Ids',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.ExternalIds],
			hideFromMenu: true,
			extra: {
				maskPath: 'program.externalIdentifier.mask',
			},
		},
		/*{
			id: 'program-region',
			name: 'Region',
			path: 'location.parent.name',
			type: 'field',
			category: 'Program',
			dependencies: [ProgramSelect.Location],
			entityTypes: ['program'],
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
					{
						path: 'settings.entities.mediaPlan.location.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
			sortable: false,
		},*/
		{
			id: 'program-country',
			name: 'Location',
			path: 'location.name',
			type: 'field',
			category: 'Program',
			dependencies: [ProgramSelect.Location],
			entityTypes: ['program'],
			extra: {
				maskPath: 'mediaPlan.location.mask',
			},
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
					{
						path: 'settings.entities.mediaPlan.location.disabled',
						condition: Condition.NEQ,
						value: true,
					},
				],
			},
			sortable: false,
		},
		{
			id: 'program-classification-status',
			name: 'Media Plan Status',
			path: 'classificationStatus',
			type: 'badge',
			category: 'Program',
			entityTypes: ['program'],
			visibilityConditions: {
				operator: LogicalConjunction.AND,
				filterConditions: [
					{
						path: 'section',
						condition: Condition.EQ,
						value: 'media-plan',
					},
				],
			},
		},
		/* {
			id: 'planned-allocated-budget',
			name: 'Plan Allocated Budget',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program', 'tactic'],
			dependencies: [ProgramSelect.BudgetCache],
			visibilityCondition: {
				path: 'section',
				condition: Condition.EQ,
				value: 'activation'
			}
		},
		{
			id: 'allocated-budget',
			name: 'Allocated Budget',
			path: 'budgetCache.amountActual',
			exportPath: 'budgetCache.amountActual',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['program'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {},
			visibilityCondition: {
				path: 'section',
				condition: Condition.NEQ,
				value: 'planning'
			}
		},
		{
			id: 'allocated-budget-from-plan',
			name: 'Allocated Budget From Plan',
			path: 'budgetCache.amountPlanned',
			exportPath: 'budgetCache.amountPlanned',
			type: 'budgetCacheValue',
			category: 'Budgets',
			entityTypes: ['plan', 'program'],
			dependencies: [ProgramSelect.BudgetCache],
			extra: {},
			visibilityCondition: {
				path: 'section',
				condition: Condition.NEQ,
				value: 'planning'
			}
		}*/
	],
};

/**
 * The default active columns when programs are being shown in tables
 */
export const ProgramDefaultActiveColumns = [
	'Name',
	//'Planned Program Budget',
	'Start Date',
	'End Date',
	//'Allocated Budget',
	//'Estimated Spend',
	//'Actual Spend',
	'Retailer',
	'Program Phase',
	'Owners',
	'shim',
	'action',
];

/**
 * The default active columns when programs are being shown in tables
 */
export const MediaPlanningDefaultActiveColumns = [
	'Name',
	'Media Plan Status',
	'Planned Budget',
	'Brand(s)',
	'Owners',
	'Tags',
	'shim',
	'action',
];

/**
 * The unique filters for programs.
 */
export const ProgramFilterCollection: Filter[] = [
	{
		id: 'program-group-by',
		name: 'Grouped By',
		category: 'overall',
		groupTypes: [FilterGroupType.displayGroup],
		matchEndpoint: 'programs',
		slug: 'groups',
		type: 'single-select',
		order: 1,
		options: [
			{
				id: 'none',
				name: '(None)',
				value: undefined,
			},
			{
				id: 'retailers',
				name: 'Retailers',
				entityName: 'Retailer',
				value: 'retailers',
				visibilityCondition: {
					path: 'settings.entities.program.retailer.disabled',
					condition: Condition.NEQ,
					value: true,
				},
				extra: {
					maskPath: 'program.retailer.mask',
				},
			},
			{
				id: 'agencies',
				name: 'Agencies',
				entityName: 'Agency',
				value: 'agencies',
				visibilityCondition: {
					path: 'settings.entities.program.agency.disabled',
					condition: Condition.NEQ,
					value: true,
				},
				extra: {
					maskPath: 'program.agency.mask',
				},
			},
			{
				id: 'brands',
				name: 'Brands',
				entityName: 'Brand',
				value: 'brands',
				extra: {
					maskPath: 'program.brand.mask',
				},
			},
			{
				id: 'brandInitiatives',
				name: 'Brand Initiatives',
				entityName: 'BrandInitiative',
				value: 'brandInitiatives',
				visibilityCondition: {
					path: 'settings.entities.program.brandInitiative.disabled',
					condition: Condition.NEQ,
					value: true,
				},
				extra: {
					maskPath: 'program.brandInitiative.mask',
				},
			},
			{
				id: 'programSectors',
				name: 'Program Sector',
				entityName: 'ProgramSector',
				value: 'programSectors',
				extra: {
					maskPath: 'program.programSector.mask',
				},
			},
			{
				id: 'programPhases',
				name: 'Program Phase',
				entityName: 'ProgramPhase',
				value: 'programPhases',
			},
			{
				id: 'tags',
				name: 'Tags',
				entityName: 'Tag',
				value: 'tags',
			},
			{
				id: 'macroObjectives',
				name: 'Macro Objectives',
				entityName: 'MacroObjective',
				value: 'macroObjectives',
			},
			{
				id: 'locationRegion',
				name: 'Region',
				entityName: 'Location',
				value: 'locationRegion',
				visibilityCondition: {
					path: 'settings.entities.program.location.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			},
			{
				id: 'locationArea',
				name: 'Area',
				entityName: 'Location',
				value: 'locationArea',
				visibilityCondition: {
					path: 'settings.entities.program.location.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			},
			{
				id: 'locationCountry',
				name: 'Country',
				entityName: 'Location',
				value: 'locationCountry',
				visibilityCondition: {
					path: 'settings.entities.program.location.disabled',
					condition: Condition.NEQ,
					value: true,
				},
			},
		],
		extra: {
			buttonSelect: {
				buttonText: 'Group By ${formGroup.groups.name}',
				buttonTextMask: 'formGroup.groups.extra.maskPath',
				emptyText: 'Group By',
			},
			iconName: 'uil-layer-group',
		},
	},
	{
		id: 'program-tactic-types',
		category: 'programs',
		groupTypes: [FilterGroupType.tactic],
		name: 'Tactic Type(s)',
		entityName: 'TacticType',
		matchEndpoint: 'programs',
		slug: 'tacticTypes',
		type: 'multi-select',
		visibilityConditions: {
			operator: LogicalConjunction.OR,
			filterConditions: [
				{
					path: 'include.value',
					condition: Condition.EQ,
					value: {
						endpoint: 'programs',
						include: ['tactics'],
					},
				},
				{
					path: 'include.value',
					condition: Condition.EQ,
					value: {
						endpoint: 'programs',
						include: ['tactics', 'invoices'],
					},
				},
			],
		},
		order: 4.7,
		options: [],
	},
	{
		id: 'program-tactic-phase',
		category: 'programs',
		groupTypes: [FilterGroupType.tactic],
		name: 'Tactic Phase',
		entityName: 'TacticPhase',
		matchEndpoint: 'programs',
		slug: 'tacticPhase',
		type: 'single-select',
		options: [],
		extra: {
			buttonSelect: {
				emptyText: 'Tactic Phase',
			},
			iconName: 'uil-clock',
		},
		visibilityConditions: {
			operator: LogicalConjunction.OR,
			filterConditions: [
				{
					path: 'include.value',
					condition: Condition.EQ,
					value: {
						endpoint: 'programs',
						include: ['tactics'],
					},
				},
				{
					path: 'include.value',
					condition: Condition.EQ,
					value: {
						endpoint: 'programs',
						include: ['tactics', 'invoices'],
					},
				},
			],
		},
		order: 4.8,
	},
	{
		id: 'program-tactic-vendors',
		category: 'programs',
		groupTypes: [FilterGroupType.tactic],
		name: 'Vendors',
		entityName: 'Vendor',
		matchEndpoint: 'programs',
		slug: 'vendors',
		type: 'multi-select',
		options: [],
		extra: {
			maskPath: 'tactic.vendors.mask',
			suggestEntity: 'vendor',
		},
		visibilityConditions: {
			operator: LogicalConjunction.OR,
			filterConditions: [
				{
					path: 'include.value',
					condition: Condition.EQ,
					value: {
						endpoint: 'programs',
						include: ['tactics'],
					},
				},
				{
					path: 'include.value',
					condition: Condition.EQ,
					value: {
						endpoint: 'programs',
						include: ['tactics', 'invoices'],
					},
				},
			],
		},
		order: 4.9,
	},
	{
		id: 'program-name',
		name: 'Program Name',
		category: 'overall',
		groupTypes: [FilterGroupType.program, FilterGroupType.plannedProgram],
		matchEndpoint: 'programs',
		slug: 'name',
		type: 'search',
		order: 5,
	},
	{
		id: 'program-location',
		name: 'Locations',
		category: 'overall',
		matchEndpoint: 'programs',
		slug: 'locations',
		type: 'multi-select',
		order: 3,
		extra: {
			suggestEntity: 'location',
			maskPath: 'program.location.mask',
		},
		visibilityCondition: {
			path: 'settings.entities.program.location.disabled',
			condition: Condition.NEQ,
			value: true,
		},
	},
	{
		id: 'program-sector',
		name: 'Program Sector',
		category: 'overall',
		matchEndpoint: 'programs',
		slug: 'programSector',
		type: 'single-select',
		options: [],
		order: 2.5,
		extra: {
			maskPath: 'program.programSector.mask',
		},
	},

	{
		id: 'program-classification-status',
		name: 'Media Plan Status',
		category: 'programs',
		groupTypes: [FilterGroupType.program],
		matchEndpoint: 'programs',
		slug: 'classificationStatus',
		type: 'single-select',
		options: [
			{ id: undefined, name: 'None' },
			{ id: ProgramClassificationStatus.Draft, name: ProgramClassificationStatus.Draft },
			{ id: ProgramClassificationStatus.Approved, name: ProgramClassificationStatus.Approved },
		],
		extra: {
			placeholder: 'Media Plan Status',
		},
		order: 2.5,
	},

	{
		id: 'date-range',
		name: 'Date Range',
		category: 'overall',
		slug: 'date-range',
		type: 'date-range',
		matchEndpoint: 'programs',
		options: [],
		extra: {
			prependDates: '',
			iconName: 'uil-calender',
			affectedProperties: ['start', 'end'],
			customWidth: 'max-width-md',
		},
	},
	{
		id: 'program-include-active-range',
		name: 'Include active for this range',
		category: 'overall',
		slug: 'lazyDate',
		type: 'toggle',
		extra: {
			classes: 'mt-2 mb-3',
		},
	},
	{
		id: 'program-funding-sources',
		name: 'Funding Source(s)',
		category: 'programs',
		groupTypes: [FilterGroupType.program],
		matchEndpoint: 'programs',
		slug: 'fundingSources',
		type: 'multi-select',
		options: [],
		order: 13,
	},
	{
		id: 'program-products',
		name: 'Products / SKUs',
		category: 'programs',
		matchEndpoint: 'programs',
		slug: 'products',
		type: 'multi-select',
		extra: {
			suggestEntity: 'product',
		},
	},
	{
		id: 'program-brand-initiatives',
		name: 'Initiatives',
		category: 'overall',
		slug: 'brandInitiatives',
		type: 'multi-select',
		order: 6,
		options: [],
		extra: {
			maskPath: 'program.brandInitiative.mask',
			optionsFromSettings: {
				path: 'brandInitiatives',
				condition: {
					path: 'budgetPeriod.id',
					condition: Condition.EQ,
					value: '${form.budgetPeriod.id}',
				},
			},
		},
	},
	{
		id: 'program-owned-by-me',
		name: 'Owned by Me',
		category: 'overall',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'programs',
		slug: 'ownedByMe',
		type: 'toggle',
	},
	{
		id: 'program-created-by-me',
		name: 'Created by Me',
		category: 'overall',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'programs',
		slug: 'createdByMe',
		type: 'toggle',
	},
	{
		id: 'program-owners',
		name: 'Owners',
		category: 'programs',
		groupTypes: [FilterGroupType.program],
		matchEndpoint: 'programs',
		slug: 'owners',
		type: 'multi-select',
		extra: {
			suggestEntity: 'user',
		},
		order: 11,
	},
	{
		id: 'program-created-by',
		name: 'Created By',
		category: 'programs',
		matchEndpoint: 'programs',
		slug: 'authors',
		type: 'multi-select',
		extra: {
			suggestEntity: 'user',
		},
	},
	{
		id: 'program-tags',
		name: 'Tags',
		category: 'programs',
		groupTypes: [FilterGroupType.program],
		matchEndpoint: 'programs',
		slug: 'tags',
		type: 'multi-select',
		extra: {
			suggestEntity: 'tag-program',
		},
		order: 14,
	},
	{
		id: 'program-at-risk',
		name: 'At Risk',
		category: 'programs',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'programs',
		slug: 'atRisk',
		type: 'toggle',
	},
	{
		id: 'program-no-tactics',
		name: 'Has No Tactics',
		category: 'programs',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'programs',
		slug: 'hasNoTactics',
		type: 'toggle',
	},
	{
		id: 'program-notes',
		name: 'Has Notes',
		category: 'programs',
		matchEndpoint: 'programs',
		slug: 'hasNotes',
		type: 'toggle-chip',
		options: [
			{
				id: 'program-full-notes',
				name: 'Off',
				value: undefined,
			},
			{
				id: 'program-notes',
				name: 'Yes',
				value: true,
			},
			{
				id: 'program-no-notes',
				name: 'No',
				value: false,
			},
		],
	},
	{
		id: 'program-files',
		name: 'Has Files',
		category: 'programs',
		matchEndpoint: 'programs',
		slug: 'hasFiles',
		type: 'toggle-multi-select',
		options: [
			{
				id: 'program-full-files',
				name: 'Off',
				value: undefined,
			},
			{
				id: 'program-files',
				name: 'Yes',
				value: true,
			},
			{
				id: 'program-no-files',
				name: 'No',
				value: false,
			},
		],
		extra: {
			controlSelectlName: 'programHasFilesInCategory',
			category: Category.FileCategory,
		},
	},
	{
		id: 'program-panels',
		name: 'Has Panels',
		category: 'programs',
		matchEndpoint: 'programs',
		slug: 'hasPanels',
		type: 'toggle-multi-select',
		options: [
			{
				id: 'program-full-panels',
				name: 'Off',
				value: undefined,
			},
			{
				id: 'program-panels',
				name: 'Yes',
				value: true,
			},
			{
				id: 'program-no-panels',
				name: 'No',
				value: false,
			},
		],
		extra: {
			controlSelectlName: 'programHasPanelsInCategory',
			category: Category.PanelLocationLayoutCodes,
		},
	},
	{
		id: 'program-objectives',
		name: 'Has Objectives',
		category: 'programs',
		matchEndpoint: 'programs',
		slug: 'hasObjectives',
		type: 'toggle-chip',
		options: [
			{
				id: 'program-full-objectives',
				name: 'Off',
				value: undefined,
			},
			{
				id: 'program-objectives',
				name: 'Yes',
				value: true,
			},
			{
				id: 'program-no-objectives',
				name: 'No',
				value: false,
			},
		],
	},
	{
		id: 'program-file-categories',
		name: 'File Category',
		category: 'programs',
		groupTypes: [FilterGroupType.additional],
		matchEndpoint: 'file',
		slug: 'programFileCategory',
		type: 'single-select',
		options: FileCategories,
	},
];
