// Section
export enum DocumentationQASectionTypeEnum {
	MAIN_TITLE = 'MAIN_TITLE',
	TITLE = 'TITLE',
	SUBTITLE = 'SUBTITLE',

	BULLETED_LIST = 'BULLETED_LIST',
	DEFAULT_PARAGRAPH = 'DEFAULT_PARAGRAPH',
}

// Formatting
export interface DocumentationQASectionFormat {
	textIdentifier: string;
	classes?: Array<'file-location'>;
	styling?: Array<'bold' | 'italic'>;
}

// QA Types
export enum DocumentationQATypeEnum {
	BUDGET_DASHBOARD = 'BudgetDashboard',
	PROGRAM_SPEND_DASHBOARD = 'ProgramSpendDashboard',
}
